<template>
  <div class="watch-page">
    <section-panel class="watch-chart" title="心率 2023年11月1日 周一">
      <chart-base :series="getLineSeries(dayData)" :color="['#d42928']" :x-axis="xAxis" no-gap></chart-base>
    </section-panel>
    <section-panel class="watch-chart" title="心率 2023年11月1日 周一" v-for="(item, index) in data" :key="index">
      <chart-base :series="getBarSeries(item)" :color="color" :x-axis="xAxis"></chart-base>
    </section-panel>
    <section-panel class="grid-span-1" title="今日数据"></section-panel>
    <section-panel class="grid-span-1" title="心率波动范围"></section-panel>
    <section-panel class="grid-span-1" title="日平均心率"></section-panel>
    <section-panel class="grid-span-1" title="本周数据"></section-panel>
    <section-panel class="grid-span-1" title="心率波动范围"></section-panel>
    <section-panel class="grid-span-1" title="本周平均心率"></section-panel>
    <section-panel class="grid-span-1" title="本月数据"></section-panel>
    <section-panel class="grid-span-1" title="心率波动范围"></section-panel>
    <section-panel class="grid-span-1" title="本月平均心率"></section-panel>
  </div>
</template>

<script>
import { graphic } from 'echarts';
import SectionPanel from './section-panel';
import ChartBase from './chart-base';

export default {
	components: { SectionPanel, ChartBase },
	data() {
		return {
			color: ['#dd7678', '#d42928'],
			xAxis: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			dayData: [{ data: [120, 118, 109, 120, 118, 109, 96, 96, 120, 118, 109, 96], name: '心率' }],
			data: [
				[
					{ data: [120, 118, 109, 120, 118, 109, 96, 96, 120, 118, 109, 96], name: '最高心率' },
					{ data: [40, 40, 63, 73, 63, 40, 63, 73, 63, 93, 73, 63], name: '最低心率' },
				],
				[
					{ data: [120, 118, 109, 120, 118, 109, 96, 96, 120, 118, 109, 96], name: '最高心率' },
					{ data: [40, 40, 63, 73, 63, 40, 63, 73, 63, 93, 73, 63], name: '最低心率' },
				],
			],
		};
	},
	methods: {
		getLineSeries(data) {
			if (!data) return [];
			return data.map((s, index) => {
				s.type = 'line';
				s.smooth = true;
				s.areaStyle = {
					color: new graphic.LinearGradient(0, 0, 0, 1, [
						{ offset: 0, color: this.color[index] },
						{ offset: 1, color: '#fff' },
					]),
				};
				return s;
			});
		},
		getBarSeries(data) {
			if (!data) return [];
			return data.map((s, index) => {
				s.type = 'bar';
				s.barMaxWidth = 30;
				s.barGap = '-100%';
				s.itemStyle = {
					barBorderRadius: 15,
					borderColor: '#d42928',
					borderWidth: 3,
				};
				return s;
			});
		},
	},
};
</script>
